$window-inner-height: var(--window-inner-height);

.header {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 16px;
  height: 30px;
}

.content-video {
  height: 300px;
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 32px;

  video {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.result-page {
  padding-bottom: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  overflow: auto;

  .btns-container {
    flex-wrap: wrap;
    // gap: 16px;
    margin-bottom: 16px;

    button {
      font-size: 16px;
      letter-spacing: 0.32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      border: none;
      position: relative;
      height: 48px;
      max-width: 47%;
      width: 100%;
      margin: 0;

      .touch-effect {
        border-radius: 100px;
      }

      &[disabled] {
        opacity: 0.5;

        .touch-effect {
          display: none;
        }
      }
    }

    .btn-container-animate {
      margin-left: 16px;

      svg {
        width: 26px;
        fill: #000;
        animation: shake2 800ms infinite ease-out;
        margin-left: 10px;
      }

      @keyframes shake2 {
        0% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }

        100% {
          transform: translateX(-5px);
        }
      }
    }

    .btn-container-full {
      font-weight: bold;
      color: #000;
      background: #234af1;
    }

    .btn-container-transparent {
      color: #234af1;
      border: 1px solid #234af1;
      background-color: transparent;
    }

    .btn-container-empty {
      margin-top: 24px;
      font-size: 14px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.7);
      height: auto;
      text-transform: uppercase;
      background-color: transparent;
      padding: 4px 0;
    }

    .btn-spinner {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #000;
      border-top-color: transparent;
      animation: spin 1.5s linear infinite;
    }
  }

  &.existent {
    .subscribe-banner {
      backdrop-filter: blur(2px);
    }
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.btn-back {
  width: 30px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  background: none;

  svg {
    width: 18px;
    fill: #fff;
  }
}

.upload-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 24px;
}

.upload-content {
  flex-grow: 1;
  width: 100%;

  .container-v1 & {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
  }

  .container-v2 & {
    padding-bottom: 64px;
  }
}

.upload-content-video {
  width: calc(100% + 32px);
  height: 280px;
  display: flex;
  justify-content: center;
  margin: 28px auto 0;
  margin-left: -16px;

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.upload-notice-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  .container-v1 & {
    margin-top: 24px;
  }

  .container-v2 & {
    margin-top: 20px;
  }
}

.upload-footer-text {
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  color: rgba(255, 255, 255, 0.32);
  margin-top: 16px;

  span {
    font-weight: bold;
  }
}

.upload-notice {
  font-size: 12px;
  line-height: 1.33;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 8px 4px;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.08);
  margin: 0 4px;

  img {
    width: 16px;
    margin-right: 6px;
  }
}

.upload-text {
  line-height: 1.5;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

.upload-images-container {
  display: flex;
  justify-content: center;
  position: relative;

  .container-v1 & {
    align-items: center;
    flex-grow: 1;
  }
}

.upload-images {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}

.upload-image-container {
  width: 100%;
  position: relative;
  border-radius: 32px;
  border: dashed 1px rgba(255, 255, 255, 0.16);
  overflow: hidden;
  margin: 0 4px;
  background: transparent;

  &:after {
    content: "";
    display: block;
    padding-top: 100%;
  }

  svg {
    width: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.upload-image-container-error {
  border-color: #ff5b3f;
}

.upload-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }
}

.results-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 24px;
  flex: 1;

  .item {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.08);
    background-image: linear-gradient(
      90deg,
      rgba(217, 217, 217, 0),
      rgba(217, 217, 217, 0.04),
      rgba(217, 217, 217, 0)
    );
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;

    &.item--processed {
      animation: none;

      img {
        width: 100%;
        height: 100%;
      }

      .image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
      object-position: top center;
    }

    .spinner-container {
      top: 40%;
    }

    .triple-spinner2 {
      border-color: #1a1b1d;
      &::before {
        animation-duration: 1.5s;
      }
    }

    .loader-text-container {
      max-width: 310px;
      top: calc(40% + 130px);
      opacity: 1;
      p {
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.7);
        text-align: center;
        line-height: 24px;
      }
    }
  }

  // button {
  //     position: absolute;
  //     right: 8px;
  //     top: 8px;
  //     width: 40px;
  //     height: 40px;
  //     border-radius: 50%;
  //     z-index: 5;

  //     svg {
  //         width: 100%;
  //     }
  // }
}

.results-grid .item--failed {
  background-color: rgba(255, 255, 255, 0.08);
  background-image: none;

  .item--failed_content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }

  img {
    position: static;
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
    transform: translate(0);
  }

  p {
    font-size: 12px;
    line-height: 1.33;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 32px;
  }

  button {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.24px;
    color: #f8d34f;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    width: 112px;
    height: 32px;
    border-radius: 100px;
    background-color: rgba(248, 211, 79, 0.32);
  }
}

.results-grid-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-left: -16px;
  margin-bottom: 16px;
  width: calc(100% + 32px);
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}

.btns-container {
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin: 0 4px;
  }
}

.btn-change {
  font-weight: bold;
  letter-spacing: 0.32px;
  color: #f8d34f;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 240px;
  height: 56px;
  border-radius: 100px;
  background-color: rgba(248, 211, 79, 0.32);
}

.btn-refresh {
  font-weight: bold;
  letter-spacing: 0.32px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 240px;
  height: 56px;
  border-radius: 100px;
  border: none;
  background: #f8d34f;
  position: relative;

  svg {
    width: 24px;
    fill: #000;
    animation: shake 800ms infinite ease-out;
    position: absolute;
    top: 50%;
    right: 24px;
  }

  &--roll {
    svg {
      animation: spin-reverse 1.5s linear infinite;
    }
  }

  &.btn-refresh--wait {
    opacity: 0.32;
    color: #f8d34f;
    background: none;
  }
}

.btn-upload-foto-container {
  position: relative;
}

.btn-upload-foto {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.32px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 254px;
  height: 60px;
  border-radius: 100px;
  background-color: #234af1;
  position: relative;
  overflow: hidden;

  .touch-effect {
    border-radius: 100px;
  }

  &[disabled] {
    opacity: 0.5;

    .touch-effect {
      display: none;
    }
  }
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d5eaff;
  z-index: 10;
}

.loader-survey {
  position: static;
  padding: 25px 33px 0;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  * {
    box-sizing: border-box;
  }

  > div:first-of-type {
    margin: 0 auto;
  }
}

.survey-container {
  width: 100%;
  margin: 90px 0 26px;

  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 16px;
  }

  max-height: calc(100vh - 384px);

  @media (max-height: 600px) {
    max-height: calc(100vh - 348px);
  }
}

.survey-btns {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  width: 100%;
  height: calc(100% - 30px);

  button {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: calc(50% - 4px);
    position: relative;
    background: none;

    img {
      box-sizing: border-box;
      border-radius: 20px;
      width: 100%;
      height: calc(100% - var(--span-height));
      object-fit: cover;
      border: 2px solid transparent;
      margin-bottom: 8px;
    }

    span {
      font-size: 14px;
      color: #fff;
      display: flex;
      text-align: left;
      line-height: 1.25;
      z-index: 1;
      padding: 0 4px;
      width: 100%;

      &::before {
        content: "";
        display: block;
        width: 100%;
        max-width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid #fff;
        box-sizing: border-box;
        margin-right: 8px;
      }

      @media (max-height: 690px) {
        font-size: 12px;
      }
    }

    // &:after {
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background-image: linear-gradient(
    //     to top,
    //     rgb(30, 43, 55),
    //     rgba(30, 43, 55, 0)
    //   );
    // }

    // &:before {
    //   content: "";
    //   display: block;
    //   width: 100%;
    //   padding-top: 134%;
    // }

    &.active {
      img {
        border-color: #234af1;
      }

      span {
        color: #234af1;

        &::before {
          border-color: #234af1;
          background: radial-gradient(#234af1 35%, transparent 35%);
        }
      }
    }

    @media (max-height: 600px) {
    }
  }
}

.survey-avatar {
  position: absolute;
  left: 50%;
  top: 35px;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-sizing: border-box;
  z-index: 10;
  overflow: hidden;

  &.active {
    border-color: #234af1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  &.survey-avatar-blur {
    img {
      filter: blur(2px);
    }
  }

  @media (max-width: 380px) {
    width: 80px;
    height: 80px;
  }

  @media (max-height: 600px) {
    top: 20px;
    width: 60px;
    height: 60px;
  }
}

.loader-cancel-button {
  font-size: 10px;
  position: absolute;
  top: 8px;
  right: 0;
  padding: 8px 16px;
  background: transparent;
  color: #888;
}

.spinner-container {
  position: absolute;
  top: calc(50% - 200px);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.triple-spinner {
  display: block;
  position: relative;
  width: 212px;
  height: 212px;
  border-radius: 50%;
  border: solid 1px rgba(255, 255, 255, 0.08);
}

.triple-spinner2 {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 177px;
  height: 177px;
  border-radius: 50%;
  border: solid 1px rgba(255, 255, 255, 0.08);
}

.triple-spinner::before,
.triple-spinner2::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 1px solid transparent;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
}

.triple-spinner::before {
  border: 1px solid #234af1;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  animation: spin-reverse 1.5s linear infinite;
}

.triple-spinner2::before {
  border: 1px solid #234af1;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: spin 3s linear infinite;
}

.triple-spinner-avatars {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.triple-spinner-avatar-1 {
  width: 92px;
  height: 92px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -5;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }
}

.triple-spinner-avatar-2 {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  transform: translate(44%, 44%);
  z-index: -5;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }
}

.triple-spinner-icon {
  width: 32px;
}

.loader-text-container {
  font-size: 14px;
  text-transform: uppercase;
  opacity: 0.7;
  position: absolute;
  top: calc(50% + 55px);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 40px);

  p {
    text-align: center;
  }

  @media all and (max-width: 330px) {
    p {
      font-size: 15px;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

.error-page {
  height: var(--window-inner-height);
  padding-top: 24px;
  padding-bottom: 24px;
  box-sizing: border-box;

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  img {
    width: 180px;
    margin-bottom: 48px;
  }

  h1 {
    color: rgba(34,34,34,.7);
    font-style: italic;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 16px;
  }

  p {
    color: rgba(34,34,34,.7);
    font-style: italic;
    line-height: 1.5;
    text-align: center;
  }

  .btn-upload-foto {
    background-color: #ff62a5;
    color: #fff;
    border: none;
    font-weight: 400;
    min-width: 182px;
    width: auto;
    height: 48px;
    text-transform: initial;
    padding: 0 16px;
  }
}

.error-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 24px;
}

.save-button {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.32px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 240px;
  height: 56px;
  border-radius: 100px;
  background-color: #f8d34f;

  span {
    display: block;
    transition: opacity 60ms;
  }

  svg {
    position: absolute;
    top: -9999px;
    left: -9999px;
    height: 24px;
    opacity: 0;
    transition: opacity 320ms;
  }

  &.pretend-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    border-radius: 50%;

    span {
      position: absolute;
      top: -9999px;
      left: -9999px;
      opacity: 0;
    }

    svg {
      width: 14px;
      position: static;
      opacity: 1;
    }
  }
}

.watermark-builder-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #000;

  header {
    width: 100%;
  }

  .btns-container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin-top: 24px;
    margin-bottom: 40px;

    button {
      width: auto;
      flex: 1 0;
    }
  }

  .btn-share {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.32px;
    color: #f8d34f;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    height: 56px;
    border-radius: 100px;
    background-color: transparent;
    border: solid 1px #f8d34f;

    svg {
      width: 68px;
      margin-right: 8px;
    }

    @media all and (max-width: 350px) {
      svg {
        width: 50px;
        margin-right: 6px;
      }
    }
  }

  .image-container {
    width: 90%;
    height: 100%;
    position: relative;
    margin: 0 auto;
  }

  .hidden-image {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .result-image {
    position: absolute;
    z-index: 10;
    border-radius: 24px;
  }

  .watermark-holder {
    position: absolute;
    z-index: 11;
  }

  .watermark-tooltip {
    position: absolute;
    z-index: 15;
    top: 50%;
    right: 110%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;

    p {
      font-size: 12px;
      color: #fff;
      background-color: rgba(34, 34, 34, 0.6);
      border-radius: 3px;
      position: relative;
      white-space: nowrap;
      line-height: 1.25;
      padding: 8px;

      &::after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 0 4px 7px;
        border-color: transparent transparent transparent rgba(34, 34, 34, 0.6);
        position: absolute;
        right: -7px;
        top: 50%;
        transform: translateY(-50%);
      }

      span {
        font-weight: 600;
      }
    }
  }
}

.sort-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;

  .btn-sort {
    width: 16px;
    height: 16px;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;

    &:last-child {
      svg {
        width: 12px;
      }
    }

    &.active {
      svg {
        fill: #f8d34f;
      }
    }
  }

  svg {
    width: 100%;
    fill: rgba(255, 255, 255, 0.32);
  }
}

.loader-watermark {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.pending-loader-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  &.relative {
    position: relative;
  }
}

.pending-loader {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  border: solid 4px rgba(0, 0, 0, 0.15);
  position: relative;
}

.pending-loader-slider {
  border: solid 4px transparent;
  border-top: solid 4px #f8d34f;
  border-right: solid 4px #f8d34f;
  position: absolute;
  top: -4px;
  left: -4px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: rotate 1.2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-white {
  color: #fff;
}

.text-pink {
  color: #fd77ab;
}

.upload-image-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.08);
  background-image: linear-gradient(
    90deg,
    rgba(217, 217, 217, 0),
    rgba(217, 217, 217, 0.04),
    rgba(217, 217, 217, 0)
  );
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;
}

.error-text {
  position: absolute;
  top: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  color: #ff5b3f;
  max-width: 360px;
  width: 100%;

  span {
    font-weight: bold;
  }
}

.results-grid-2 {
  position: relative;
  max-height: calc(100vh - 85px);
  overflow: hidden;
  width: calc(100% + 32px);
  padding: 0 18px;
  box-sizing: border-box;
  margin-bottom: 0;
  margin-left: -16px;

  .item {
    background-image: linear-gradient(
      90deg,
      rgba(217, 217, 217, 0),
      rgba(217, 217, 217, 0.3),
      rgba(217, 217, 217, 0)
    );
    background-size: 86px 100%;
  }
}

.subscribe-banner {
  position: absolute;
  top: -8px;
  left: 0;
  width: 100%;
  height: calc(100% + 8px);
  backdrop-filter: blur(5px);
  background-color: rgba(5, 19, 32, 0.75);
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 20px 16px;

  h2 {
    font-size: 14px;
    line-height: 1.71;
    color: rgba(255, 255, 255, 0.7);
    position: relative;
    max-width: 260px;
    text-transform: uppercase;
    // margin: 0 auto 16px;

    svg {
      width: 32px;
      position: absolute;
      left: -20px;
      top: -20px;
    }
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    position: relative;
    max-width: 350px;
    margin: 0 auto;

    svg {
      position: absolute;
      right: 0;
      top: calc(100% - 12px);
      width: 32px;
    }
  }

  .subscribe-btn {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.32px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    text-transform: uppercase;
    width: 100%;
    height: 56px;
    border-radius: 100px;
    background-color: #f8d34f;
    position: relative;
    right: auto;

    svg {
      fill: #000;
      width: 25px;
      margin-left: 16px;
      animation: shake 800ms infinite ease-out;
      position: absolute;
      top: 50%;
      right: 24px;
    }
  }

  @keyframes shake {
    0% {
      transform: translateX(-5px) translateY(-50%);
    }
    50% {
      transform: translateX(5px) translateY(-50%);
    }

    100% {
      transform: translateX(-5px) translateY(-50%);
    }
  }

  .price-text {
    font-size: 10px;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    color: rgba(255, 255, 255, 0.32);
    margin-top: 8px;
    margin-bottom: 24px;

    span {
      text-transform: uppercase;
    }
  }

  &.purchase-banner {
    padding-top: 38px;

    h2 {
      max-width: max-content;
      margin-bottom: 8px;

      svg {
        left: -36px;
        top: -16px;
      }
    }

    p {
      margin-bottom: 24px;
    }

    .subscribe-btn {
      max-width: 240px;
      margin: 0 auto;
    }
  }
}

.subscribe-banner-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/assets/images/bg-subscribe.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  border-radius: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 30px;

  h2 {
    font-size: 20px;
    line-height: 1.65;
    margin-bottom: 16px;
    color: #fff;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-color {
    color: #234af1;
  }

  .text-big {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 16px;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.7);
  }
}

.subscribe-text {
  font-size: 10px;
  line-height: 1.2;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 2px;

  span {
    text-decoration: underline;
  }
}

.subscribe-footer-container {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 8px;
}

.subscribe-footer-container-hide {
  visibility: hidden;
}

.subscribe-footer {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    font-size: 10px;
    font-weight: bold;
    line-height: 1.2;
    color: rgba(255, 255, 255, 0.32);
    position: relative;
    top: 0;
    right: 0;
    width: auto;
    height: auto;
    background: none;
    border-radius: 0;
    padding: 4px;
    margin: 0 3px;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -3px;
      display: block;
      height: 12px;
      width: 1px;
      background-color: rgba(255, 255, 255, 0.32);
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }
}

.text-bold {
  font-weight: bold;
}

.text-yellow {
  color: #f8d34f;
}

.upload-image-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  svg {
    width: 40px;
    position: static;
    transform: none;
  }

  p {
    font-size: 12px;
    line-height: 1.33;
    text-align: center;
    color: rgba(255, 255, 255, 0.32);
    margin-top: 8px;
  }
}

.-show-popup {
  overflow: hidden;
}

.web {
  .watermark-builder-modal {
    padding-bottom: 96px;
    box-sizing: border-box;
  }

  @media all and (max-width: 992px) and (orientation: portrait) {
    .upload-content-container {
      min-height: auto;
    }
  }
}

.result-page--subscribe-view-animated {
  .subscribe-btn {
    animation: buttonShake 1000ms infinite ease-out;
  }
}

@keyframes borderPulse {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(248, 211, 79, 0.5);
  }
  100% {
    box-shadow: 0px 0px 0px 12px rgba(248, 211, 79, 0);
  }
}

@keyframes buttonShake {
  10%,
  90% {
    transform: translate3d(-4px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}

.result-notice {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  backdrop-filter: blur(2px);
  background-color: rgba(5, 19, 32, 0.8);
  z-index: 100;
  padding: 48px 10px 16px;

  p {
    font-weight: bold;
    line-height: 1.5;
    color: #f8d34f;
    display: flex;
    align-items: center;
  }

  .text-decoration {
    text-decoration: underline;
  }

  svg {
    width: 24px;
    margin-left: 6px;
  }

  @media all and (max-width: 369px) {
    p {
      font-size: 14px;
    }
  }

  @media all and (max-width: 330px) {
    svg {
      width: 18px;
      margin-left: 2px;
    }
  }
}

.swiper {
  height: 100%;
  // max-height: 74vh;
  width: 100%;
}

.slider-counter {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.67;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.slider-nav {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding: 0 24px;
  margin-bottom: 16px;

  button {
    width: 33px;
    height: 16px;
    background: none;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 24px;
      height: 8px;
      fill: rgba(255, 255, 255, 0.4);
    }
  }

  .slider-nav-prev {
    left: 24px;
  }

  .slider-nav-next {
    right: 24px;
  }
}

.ios {
  .result-page .btns-container {
    margin-bottom: 30px;
  }

  .watermark-builder-modal {
    .btns-container {
      padding: 0 20px;
      margin-top: 10px;
      margin-bottom: 30px;
    }

    .image-container {
      max-height: 74%;
    }
  }
}

.creative-promt {
  @keyframes shake3 {
    0% {
      transform: translate(-5px, 5px);
    }
    50% {
      transform: translate(5px, -5px);
    }

    100% {
      transform: translate(-5px, 5px);
    }
  }

  position: absolute;
  top: -80px;
  z-index: 10;
  color: #234af1;
  font-size: 14px;
  font-style: italic;
  text-align: center;
  width: 100%;
  margin: 0 auto;

  p {
    font-weight: 300;
    line-height: 17px;
    max-width: 260px;
    margin: 0 auto;
  }

  svg {
    width: 35px;
    position: absolute;
    top: calc(100% + 5px);
    left: 22px;
    animation: shake3 800ms infinite ease-out;
  }
}

@media all and (min-width: 992px) and (orientation: landscape) {
  .header {
    font-size: 24px;
    line-height: 1.67;
    position: static;
    height: 40px;
    margin-top: 40px;
  }

  .btn-back {
    top: 48px;
    left: 80px;
    transform: none;

    svg {
      width: 25px;
    }
  }

  .btn-refresh svg {
    margin-right: 8px;
  }

  .content-video {
    height: 320px;
    margin-bottom: 48px;
  }

  .upload-images-container {
    justify-content: center;

    .container-v1 & {
      flex-grow: 1;
    }
  }

  .upload-image-container {
    width: 170px;
    align-self: center;
  }

  .upload-content-container {
    .container-v1 & {
      padding-bottom: 88px;
    }
  }

  .upload-content {
    display: flex;
    flex-direction: column;
  }

  .upload-notice-container {
    .container-v1 & {
      margin-top: 40px;
    }
  }

  .results-grid {
    max-width: 350px;
    width: 100%;
    width: 350px;
    margin: 16px auto 24px;
  }

  .sort-container {
    margin: 40px auto 0;
    max-width: 350px;
    width: 100%;
  }

  .result-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-bottom: 40px;

    .container {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex-grow: 1;
    }
  }

  .btns-container {
    max-width: 350px;
    align-items: flex-end;
    width: 100%;
    margin: 0 auto;
  }

  .watermark-builder-modal {
    backdrop-filter: blur(16px);
    background-color: rgba(5, 19, 32, 0.75);

    .image-container {
      max-width: 400px;
      flex-grow: 1;
    }
  }
}

@media all and (max-width: 540px) {
  .result-page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    overflow: auto;
  }
}

@media all and (max-height: 900px) and (orientation: landscape) {
  .content-video {
    height: 300px;
  }
}

@media all and (max-height: 860px) and (orientation: landscape) {
  .content-video {
    margin-bottom: 24px;
  }
}

@media all and (max-height: 800px) and (orientation: landscape) {
  .container-v2 {
    .upload-content {
      padding-bottom: 46px;
    }

    .upload-content-video {
      height: 240px;
      margin-top: 8px;
    }

    .upload-image-container {
      width: 160px;
    }
  }
}

@media all and (max-height: 760px) and (orientation: landscape) {
  .content-video {
    margin-bottom: 24px;
  }
}

@media all and (max-height: 700px) and (orientation: landscape) {
  .content-video {
    height: 280px;
  }


  .container-v1 {
    .upload-images-container {
      margin-top: 24px;
    }

    .upload-content-container {
      padding-bottom: 60px;
    }
  }

  .container-v2 {
    .header {
      margin-top: 16px;
    }

    .btn-back {
      top: 24px;
    }

    .upload-image-container {
      width: 130px;
    }

    .upload-content-video {
      height: 200px;
    }
  }
}

@media all and (max-height: 630px) and (orientation: landscape) {
  .container-v1 .upload-images-container {
    align-items: flex-start;
  }

  .container-v1 .upload-notice-container {
    margin-top: 16px;
  }

  .container-v1 .upload-content-container {
    padding-bottom: 24px;
  }

  .container-v2 .upload-content-video {
    height: 140px;
  }
}

@media all and (max-height: 600px) and (orientation: landscape) {
  .content-video {
    height: 280px;
  }
}

@media all and (max-height: 550px) and (orientation: landscape) {
  .content-video {
    margin-bottom: 16px;
  }

  .content-video {
    height: 260px;
  }
}

@media all and (max-width: 370px) and (orientation: portrait) {
  .btn-refresh svg {
    width: 16px;
    margin-right: 8px;
  }

  .btn-refresh,
  .btn-change {
    font-size: 14px;
  }
}

@media all and (max-height: 780px) and (orientation: portrait) {
  .container-v2 {
    .upload-content-video {
      height: 250px;
      margin-top: 8px;
    }

    .upload-notice-container {
      margin-top: 16px;
    }

    .upload-content {
      padding-bottom: 46px;
    }
  }
}

@media all and (max-height: 750px) and (orientation: portrait) {

  .content-video {
    margin-bottom: 16px;
  }
}

@media all and (max-height: 710px) and (orientation: portrait) {
  .container-v2 {
    .upload-content-video {
      height: 230px;
    }
  }

  .results-grid .item {
    // width: 90%;
    margin: 0 auto;
  }
}

@media all and (max-height: 660px) and (orientation: portrait) {
  .container-v2 {
    .upload-content-video {
      height: 200px;
    }

    .upload-image-container {
      // width: 42%;
      border-radius: 24px;
    }

    .upload-image-icon svg {
      width: 24px;
    }

    .upload-image-icon p {
      margin: 0;
    }
  }
}

// @media all and (max-height: 640px) and (orientation: portrait) {
//     .results-grid .item {
//         width: 86%;
//     }
// }

@media all and (min-height: 520px) and (orientation: portrait) {
  .content-video {
    // width: 100%;
    height: auto;
    width: calc(100% + 32px);
    max-width: initial;
  }
}

@media all and (max-height: 640px) and (orientation: portrait) {
  .content-video {
    margin-top: -30px;
  }
}

@media all and (max-height: 630px) and (orientation: portrait) {
  .container-v2 .upload-content-video {
    height: 160px;
  }
}

@media all and (max-height: 600px) and (orientation: portrait) {
  .container-v2 .upload-content-video {
    height: 120px;
  }
}

// @media all and (max-height: 590px) and (orientation: portrait) {
//     .results-grid .item {
//         width: 76%;
//     }
// }

@media all and (max-height: 580px) and (orientation: portrait) {
  .container-v1 .upload-notice-container,
  .container-v1 .upload-notice-container {
    margin-top: 16px;
  }

  .upload-content {
    padding-bottom: 16px;
  }

  .container-v1 .upload-images-container {
    align-items: flex-start;
    margin-top: 16px;
  }
}

@media all and (max-height: 570px) and (orientation: portrait) {
  .container-v2 {
    .upload-image-container {
      width: 38%;
    }

    .header {
      margin-top: 10px;
    }

    .btn-upload-foto {
      font-size: 12px;
      width: 200px;
      height: 40px;
    }
  }

  .upload-footer-text {
    font-size: 8px;
    max-width: 240px;
    margin-top: 4px;
  }
}

@media all and (max-height: 540px) and (orientation: portrait) {
  .content-video {
    // height: 210px;
    margin-bottom: 8px;
  }

  .upload-text {
    font-size: 14px;
    line-height: 1.25;
  }
}

@media all and (max-height: 530px) and (orientation: portrait) {
  .container-v2 {
    .upload-content-container {
      padding-bottom: 16px;
    }

    .upload-footer-text {
      margin-top: 12px;
    }

    .btn-upload-foto {
      height: 42px;
    }
  }

  .subscribe-banner {
    padding: 34px 20px 10px;
  }

  .subscribe-banner h2 {
    margin-bottom: 12px;
  }

  .upload-images {
    width: 80%;
  }

  // .results-grid .item {
  //     width: 68%;
  // }

  .results-grid-list,
  .slider-counter {
    margin-bottom: 8px;
  }
}

@media all and (max-height: 500px) and (orientation: portrait) {
  .subscribe-banner p {
    margin-bottom: 24px;
  }

  .subscribe-banner h2 {
    font-size: 22px;
    line-height: 1.5;
    margin-bottom: 12px;
  }

  // .results-grid .item {
  //     width: 58%;
  // }
}

@media all and (max-height: 450px) and (orientation: portrait) {
  .subscribe-banner .price-text {
    margin-bottom: 16px;
  }

  .subscribe-banner h2 {
    font-size: 20px;
  }

  .subscribe-banner p {
    font-size: 14px;
    line-height: 1.4;
  }

  // .results-grid .item {
  //     width: 56%;
  // }
}

.cards-result {
  box-sizing: border-box;
  max-height: calc(var(--window-inner-height) - 213px);

  @media (max-width: 1024px) {
    max-height: calc(var(--window-inner-height) - 179px);
  }
}
